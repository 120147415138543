@font-face {
  font-family: 'SouthwestSans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('SouthwestSans Regular'), url('./assets/fonts/SouthwestSansW01-Regular.ttf') format('truetype');
 }

 @font-face {
  font-family: 'SouthwestSans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('SouthwestSans Medium'), url('./assets/fonts/SouthwestSansW01-Medium.ttf') format('truetype');
 }

 @font-face {
  font-family: 'SouthwestSans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('SouthwestSans Light'), url('./assets/fonts/SouthwestSansW01-Light.ttf') format('truetype');
 }

 @font-face {
  font-family: 'SouthwestSans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('SouthwestSans Bold'), url('./assets/fonts/SouthwestSansW01-Bold.ttf') format('truetype');
 }

 @font-face {
  font-family: 'SouthwestSans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('SouthwestSans Black'), url('./assets/fonts/SouthwestSansW01-Black.ttf') format('truetype');
 }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'interstate', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  font-family: interstate, sans-serif;
}
